<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12">
      <div class="card card-body">
        <div>
          <button class="btn btn-relief-success" @click="add()">
            <i class="fa fa-plus"></i>
            إضافة معلم جديد
          </button>
          &nbsp;
          <button class="btn btn-relief-info" v-b-modal.modal-2>
            <i class="fa fa-upload"></i>
            استيراد
          </button>
          &nbsp;
          <button class="btn btn-relief-primary btn-lg" @click="save()">
            <i class="fa fa-save"></i>
            حفظ التعديلات
          </button>
        </div>
        <div class="col-12 table-responsive" v-if="teachers.length">
          <br />
          <table class="table table-hover table-bordered custom-table">
            <thead>
              <th>الإسم</th>
              <th>الإسم المختصر</th>
              <th>انصبة الحصص</th>
              <th>تعديل</th>
              <th>حذف</th>
            </thead>
            <tbody>
              <tr v-for="teacher in teachers" :key="teacher.id">
                <td
                  v-b-modal.modal-1
                  @click="t = teacher"
                  :id="'v_' + teacher.id"
                >
                  {{ teacher.fullname }}
                </td>
                <td v-b-modal.modal-1 @click="t = teacher">
                  {{ teacher.shortname }}
                </td>
                <td v-b-modal.modal-1 @click="t = teacher">
                  {{ teacher.selectedLessons }} من {{ teacher.lessons }} حصة
                </td>
                <td v-b-modal.modal-1 @click="t = teacher">
                  <button class="btn btn-sm btn-success">
                    <i class="fa fa-cog"></i>
                    تعديل
                  </button>
                </td>
                <td>
                  <button
                    class="btn btn-sm btn-link text-danger"
                    @click="deleteTeacher(teacher.id)"
                  >
                    <i class="fa fa-trash"></i>
                    حذف
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      size="lg"
      title="تعديل المعلم"
      ok-only
      ok-title="اغلاق"
      v-if="t.fullname"
    >
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <label for="">إسم المعلم</label>
            <input type="text" class="form-control" v-model="t.fullname" />
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <label for="">الإسم المختصر</label>
            <input type="text" class="form-control" v-model="t.shortname" />
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <label for="">رقم الجوال</label>
            <input type="text" class="form-control" v-model="t.phone" />
          </div>
        </div>
        <div class="col-12">
          <p>أيام العمل</p>
          <template v-for="day in days">
            <b-form-checkbox
              :key="day.code"
              v-model="t.days[day.code]"
              :value="true"
              name="check-button"
              inline
            >
              {{ day.name }}
            </b-form-checkbox></template
          >
        </div>
        <div class="col-12 g"></div>
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <label for="">نصاب الحصص</label>
            <input
              type="number"
              class="form-control"
              @change="checkLessons()"
              v-model="t.lessons"
            />
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <label for="">رقم المجموعة</label>
            <input type="text" class="form-control" v-model="t.group_number" />
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form-group">
            <label for="">نصاب الانتظار</label>
            <input type="number" class="form-control" v-model="t.waiting" />
          </div>
        </div>
        <div class="col-12 g"></div>
        <div class="col-12 col-lg-7 c">
          <div class="col-12">
            <table class="table table-hover table-bordered custom-table">
              <thead>
                <th>
                  اليوم
                  <button class="btn btn-sm btn-link" @click="addModDay()">
                    <i class="fa fa-plus"></i>
                    إضافة
                  </button>
                </th>
                <th>العمل الإشرافي</th>
              </thead>
              <tbody>
                <tr v-for="d in t.mod_days" :key="d.id">
                  <td>
                    <input
                      type="text"
                      style="width: 100%; border: none"
                      v-model="d.day"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      style="width: 100%; border: none"
                      v-model="d.text"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12 table-responsive custom-table">
          <table class="table table-hover table-bordered">
            <thead>
              <th>اليوم</th>
              <th>الأولى</th>
              <th>الثانية</th>
              <th>الثالثة</th>
              <th>الرابعة</th>
              <th>الخامسة</th>
              <th>السادسة</th>
              <th>السابعة</th>
              <th>الثامنة</th>
            </thead>
            <tbody>
              <tr v-for="day in days" :key="day.code">
                <td>{{ day.name }}</td>
                <td v-for="index in 8" :key="index">
                  <b-form-checkbox
                    v-model="t.banned_days[day.code + '|' + index]"
                    :value="index"
                    name="check-button"
                    inline
                  >
                    حجز
                  </b-form-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 g" v-if="t.lessons > 0">
          <div
            :class="
              'card card-body border' +
              (new Number(t.selectedLessons) > new Number(t.lessons)
                ? ' bg-danger text-white'
                : '')
            "
            style="border: solid 2px darkred !important"
          >
            <p class="text-muted">انصبة المواد</p>
            <div class="col-12 table-responsive">
              <table class="table table-hover table-bordered custom-table">
                <tbody>
                  <template v-for="a in t.lessons_list">
                    <template v-if="a">
                      <tr :key="a.subject">
                        <td>
                          {{ subjectName(a.subject_id) }}
                          <br />
                          <small>{{ a.classname }} - {{ a.classroom }}</small>
                        </td>
                        <td>عدد الحصص: {{ a.value }}</td>
                      </tr></template
                    ></template
                  >
                  <tr class="bg-light-primary">
                    <td>الإجمالي</td>
                    <td>{{ t.selectedLessons }} حصة من {{ t.lessons }} حصة</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12">
          <p>المواد المسموحة له</p>
          <app-collapse>
            <app-collapse-item
              style="margin-top: 10px"
              v-for="subject in subjects"
              :key="subject.id"
              :title="subject.fullname"
              ><app-collapse>
                <app-collapse-item
                  v-for="c in classes"
                  :key="c.id"
                  :title="c.title"
                >
                  <template v-for="classroom in c.classrooms">
                    <b-form-checkbox
                      :key="classroom.id"
                      v-model="
                        t.allowed[c.id + ',' + classroom.id + ',' + subject.id]
                      "
                      :value="c.id + ',' + classroom.id + ',' + subject.id"
                      name="check-button"
                      :class="isSelectClassroomsDisabled"
                      @change="
                        checkLessons(
                          c.id + ',' + classroom.id + ',' + subject.id
                        )
                      "
                      inline
                    >
                      {{ classroom.title }}
                    </b-form-checkbox>
                  </template>
                </app-collapse-item>
              </app-collapse>
            </app-collapse-item>
          </app-collapse>
        </div>
      </div>
    </b-modal>
    <!-- basic modal -->
    <b-modal
      id="modal-2"
      size="sm"
      title="استيراد المعلمين"
      ok-only
      ok-title="اغلاق"
    >
      <div class="row">
        <div class="col-12 g">
          <button
            class="btn btn-block btn-lg btn-relief-success"
            @click="importFromTahdir()"
          >
            <img
              :src="require('@/assets/images/tahdir.png')"
              style="width: 50px"
            />
            استيراد المعلمين من حسابك
          </button>
        </div>
        <div class="col-12 g" v-if="1 == 2">
          <button
            class="btn btn-block btn-lg btn-relief-secondary"
            v-b-modal.modal-3
            @click="importFromExcel()"
          >
            <img
              :src="require('@/assets/images/noor.png')"
              style="
                width: 50px;
                background: #fff;
                padding: 5px;
                border-radius: 50%;
              "
            />
            استيراد من نور
          </button>
        </div>
      </div>
    </b-modal>
    <!-- basic modal -->
    <b-modal id="modal-3" title="استيراد المعلمين" ok-only ok-title="اغلاق">
      <h4 class="text-success">
        <i class="fa fa-file"></i>
        قم برفع ملف بيانات معلمي المدرسة من نظام نور (Ecxel)
      </h4>
      <small>ملف مثل: GetSchoolTeachersDataReport.xlsx</small>
      <input type="file" name="" id="ingredient_file" />
      <div id="progress-wrp">
        <div class="progress-bar"></div>
        <div class="status">0%</div>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  BOverlay,
  BModal,
  BFormRadioGroup,
  BFormCheckbox,
} from "bootstrap-vue";
export default {
  components: {
    BOverlay,
    BModal,
    BFormRadioGroup,
    BFormCheckbox,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      isSelectClassroomsDisabled: "disabled__aa0",
      loading: true,
      user: user,
      teachers: [],
      classes: [],
      subjects: [],
      t: {},
      days: [
        {
          code: "1",
          name: "الأحد",
          list: {},
        },
        {
          code: "2",
          name: "الاثنين",
          list: {},
        },
        {
          code: "3",
          name: "الثلاثاء",
          list: {},
        },
        {
          code: "4",
          name: "الاربعاء",
          list: {},
        },
        {
          code: "5",
          name: "الخميس",
          list: {},
        },
      ],
      settings: {},
    };
  },
  created() {
    var g = this;
    $.post(api + "/table/settings", {
      jwt: user.jwt,
      type: "classes",
    })
      .then(function (r) {
        r = JSON.parse(r);
        g.classes = r.list ? r.list : [];
        $.post(api + "/table/settings", {
          jwt: user.jwt,
          type: "subjects",
        })
          .then(function (r) {
            r = JSON.parse(r);
            g.subjects = r;
            g.subjects = g.subjects.list ? g.subjects.list : [];
            $.post(api + "/table/settings", {
              jwt: user.jwt,
              type: "teachers",
            })
              .then(function (r) {
                r = JSON.parse(r);
                g.teachers = r.exists != "no" ? r.list : [];
                g.loading = false;
                if (window.location.href.includes("#")) {
                  var i = setInterval(() => {
                    if ($("#modal-1").is(":visible")) {
                      clearInterval(i);
                    } else {
                      document
                        .getElementById(
                          "v_" + window.location.href.split("#")[1]
                        )
                        .click();
                    }
                  }, 10);
                }
              })
              .catch(function () {
                swal.fire(
                  "حدث خطأ اثناء الاتصال بالخادم",
                  "برجاء المحاولة مرة أخرى",
                  "warning"
                );
                g.loading = false;
              });
          })
          .catch(function () {
            swal.fire(
              "حدث خطأ اثناء الاتصال بالخادم",
              "برجاء المحاولة مرة أخرى",
              "warning"
            );
            g.loading = false;
          });
      })
      .catch(function () {
        swal.fire(
          "حدث خطأ اثناء الاتصال بالخادم",
          "برجاء المحاولة مرة أخرى",
          "warning"
        );
        g.loading = false;
      });
  },
  methods: {
    add() {
      this.teachers.push({
        id: Math.random().toString().split(".")[1],
        fullname: "معلم بدون اسم",
        shortname: "معلم",
        days: {
          1: "true",
          2: "true",
          3: "true",
          4: "true",
          5: "true",
        },
        lessons: 0,
        group_number: null,
        waiting: null,
        phone: null,
        selectedLessons: 0,
        lessons_list: [],
        mod_days: [
          { id: Math.random().toString().split(".")[1], day: null, text: null },
        ],
        banned_days: { e: 1 },
        allowed: { e: 1 },
      });
    },
    deleteTeacher(id) {
      if (confirm("متأكد من حذف المعلم؟")) {
        var arr = [];
        this.teachers.forEach((teacher) => {
          if (teacher.id != id) {
            arr.push(teacher);
          }
        });
        this.teachers = arr;
      }
    },
    addModDay() {
      this.t.mod_days.push({
        id: Math.random().toString().split(".")[1],
        day: null,
        text: null,
      });
    },
    save(l = false) {
      var g = this;
      g.loading = true;
      $.post(api + "/table/settings/save", {
        jwt: user.jwt,
        type: "teachers",
        value: JSON.stringify({
          list: this.teachers
        }),
      })
        .then(function (r) {
          g.loading = false;
          swal.fire("تم بنجاح", "", "success");
          g.viewSave = false;
        })
        .catch(function () {
          swal.fire(
            "حدث خطأ اثناء الاتصال بالخادم",
            "برجاء المحاولة مرة أخرى",
            "warning"
          );
          g.loading = false;
        });
    },
    checkLessons(id = false, r = false) {
      var g = this,
        t = this.t;
      t.selectedLessons = 0;
      this.teachers.forEach(function (a) {
        for (var [key, value] of Object.entries(a.allowed)) {
          if (key != "e" && value != false) {
            if (value == id && a.id != t.id) {
              t.allowed[id] = false;
              swal.fire(
                "خطأ",
                "هذا المادة يتم تدريسها لهذا الفصل بواسطة المعلم " + a.fullname,
                "warning"
              );
              t.lessons_list.forEach(function (b) {
                t.selectedLessons = t.selectedLessons + new Number(b.value);
              });
              this.checkT(t);
              return false;
            }
          }
        }
      });
      t.lessons_list = [];
      for (var [key, value] of Object.entries(t.allowed)) {
        if (key != "e" && value != false) {
          var o = value;
          g.classes.forEach(function (c) {
            c.classrooms.forEach(function (cr) {
              if (cr.id == o.split(",")[1]) {
                for (var [key, value] of Object.entries(cr.subjects)) {
                  if (key == o.split(",")[2]) {
                    t.lessons_list.push({
                      class_id: o.split(",")[0],
                      classroom_id: o.split(",")[1],
                      subject_id: o.split(",")[2],
                      classname: c.title,
                      classroom: cr.title,
                      fullid: o,
                      value: value,
                    });
                  }
                }
              }
            });
          });
        }
      }
      t.selectedLessons = 0;
      t.lessons_list.forEach(function (b) {
        t.selectedLessons = t.selectedLessons + new Number(b.value);
      });
      if (t.selectedLessons > t.lessons) {
        t.allowed[id] = false;
        t.selectedLessons = 0;
        var i = 0;
        t.lessons_list.forEach(function (b) {
          if (b.fullid == id) {
            delete t.lessons_list[i];
          } else {
            t.selectedLessons = t.selectedLessons + new Number(b.value);
          }
          i++;
        });
        swal.fire(
          "خطأ",
          "عدد الحصص تجاوز النصاب المسموح به. يمكنك زيادة نصاب الحصص لهذا المعلم",
          "warning"
        );
      }
      this.checkT(t);
    },
    checkT(q) {
      var t = this.t,
        a = {};
      for (var [key, value] of Object.entries(t)) {
        a[key] = value;
      }
      this.t = a;
      var v = [];
      this.teachers.forEach(function (c) {
        if (c.id == q.id) {
          v.push(q);
        } else {
          v.push(c);
        }
      });
      this.teachers = v;
    },
    subjectName(id) {
      var n = null;
      this.subjects.forEach(function (a) {
        if (a.id == id) {
          n = a.fullname;
        }
      });
      return n;
    },
    importFromTahdir() {
      if (
        confirm(
          "متأكد من استيراد المعلمين من حسابك في نظام المعلمين في التحضير الذكي؟"
        )
      ) {
        var g = this;
        $.post(api + "/table/import-from-tahdir", {
          jwt: user.jwt,
        })
          .then(function (r) {
            r = JSON.parse(r);
            r.forEach(function (teacher) {
              g.teachers.push({
                id: Math.random().toString().split(".")[1],
                fullname: teacher.name,
                shortname: teacher.name,
                days: {
                  1: "true",
                  2: "true",
                  3: "true",
                  4: "true",
                  5: "true",
                },
                lessons: 0,
                group_number: null,
                waiting: null,
                phone: teacher.phone,
                selectedLessons: 0,
                lessons_list: [],
                mod_days: [
                  {
                    id: Math.random().toString().split(".")[1],
                    day: null,
                    text: null,
                  },
                ],
                banned_days: { e: 1 },
                allowed: { e: 1 },
              });
            });
            g.loading = false;
            $("#modal-2___BV_modal_footer_ > button").click();
          })
          .catch(function () {
            swal.fire(
              "حدث خطأ اثناء الاتصال بالخادم",
              "برجاء المحاولة مرة أخرى",
              "warning"
            );
            g.loading = false;
          });
      }
    },
    importFromExcel() {
      var g = this;
      var Upload = function (file) {
        this.file = file;
      };

      Upload.prototype.getType = function () {
        return this.file.type;
      };
      Upload.prototype.getSize = function () {
        return this.file.size;
      };
      Upload.prototype.getName = function () {
        return this.file.name;
      };
      Upload.prototype.doUpload = function () {
        var that = this;
        var formData = new FormData();

        // add assoc key values, this will be posts values
        formData.append("file", this.file, this.getName());
        formData.append("upload_file", true);

        $.ajax({
          type: "POST",
          url: brmja_extra + "/excel",
          xhr: function () {
            var myXhr = $.ajaxSettings.xhr();
            if (myXhr.upload) {
              myXhr.upload.addEventListener(
                "progress",
                that.progressHandling,
                false
              );
            }
            return myXhr;
          },
          success: function (data) {
            $("#progress-wrp").fadeOut("slow");
            console.log(data);
            data.forEach(function (student) {});
          },
          error: function (error) {
            // handle error
            alert("حدث خطأ");
          },
          async: true,
          data: formData,
          cache: false,
          contentType: false,
          processData: false,
          timeout: 60000,
        });
      };

      Upload.prototype.progressHandling = function (event) {
        var percent = 0;
        var position = event.loaded || event.position;
        var total = event.total;
        var progress_bar_id = "#progress-wrp";
        if (event.lengthComputable) {
          percent = Math.ceil((position / total) * 100);
        }
        // update progressbars classes so it fits your code
        $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
        $(progress_bar_id + " .status").text(percent + "%");
      };
      setTimeout(() => {
        $("#ingredient_file").on("change", function (e) {
          $("#progress-wrp").fadeIn("slow");
          var file = $(this)[0].files[0];
          var upload = new Upload(file);

          // maby check size or type here with upload.getSize() and upload.getType()

          // execute upload
          upload.doUpload();
        });
      }, 1000);
    },
  },
};
</script>

<style>
.disabled__aa * {
  pointer-events: none;
}
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>